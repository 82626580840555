import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import {
  DashboardMonthlySummaryRes,
  getDashboardMonthlySummary,
} from 'features/dashboard/api/services/dashboardTotalSerivces'
import { logoutUser } from 'shared/features/logout/action/logoutActions'

export const getDashboardMonthlySummaryAction = createAsyncThunk<
  DashboardMonthlySummaryRes,
  null,
  { rejectValue: ReduxError }
>('getDashboardMonthlySummary', async (_, thunkApi) => {
  const response = await getDashboardMonthlySummary()
    .then((res) => {
      if (res.req !== 'dashboardMonthlySummary') {
        return thunkApi.rejectWithValue({
          message: 'error',
        })
      } else if (res.login == 'login') {
        thunkApi.dispatch(logoutUser(null))
        return res
      } else {
        return res
      }
    })
    .catch((err) => {
      const { message } = err
      return thunkApi.rejectWithValue({
        message: message,
      })
    })
  return response
})
