import { combineReducers } from '@reduxjs/toolkit'
import dashboardTotalReducer from 'features/dashboard/logic/slice/dashboardTotalSlice'
import dashboardOverallReducer from 'features/dashboard/logic/slice/dashboardOverallSlice'
import dashboardFuelReducer from 'features/dashboard/logic/slice/dashboardFuelSlice'
import loginReducer from 'features/loginPage/logic/slice/loginSlice'
import vehicleHistoryReducer from 'features/vehiclesList/logic/slices/vehicleHistorySlice'
import { authReducer, logoutReducer, vehicleListReducer } from 'shared/features'

const rootReducer = combineReducers({
  loginReducer,
  vehicleListReducer,
  vehicleHistoryReducer,
  logoutReducer,
  authReducer,
  dashboardTotalReducer,
  dashboardOverallReducer,
  dashboardFuelReducer,
})

export default rootReducer
