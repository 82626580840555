import { Button, ButtonGroup, styled } from '@mui/material'
import { ReactNode } from 'react'

const CustomButton = styled(
  ({
    children,
    isSelected,
    value,
    ...otherProps
  }: {
    children: ReactNode
    isSelected: boolean
    onClick: (e: any) => void
    value: string
  }) => (
    <Button {...otherProps} value={value}>
      {children}
    </Button>
  )
)`
  border: ${(props) => (props.isSelected ? '2px solid #006EB8' : '2px solid #ececf0')};
  background: ${(props) => (props.isSelected ? 'rgba(0, 110, 184, 0.08)' : '#fff')};
  font-weight: ${(props) => (props.isSelected ? '700' : '400')};

  &:hover {
    border-width: 2px;
  }

  &.MuiButtonGroup-grouped:not(:last-of-type) {
    border-right-color: ${(props) => (props.isSelected ? '#006EB8' : '#ececf0')};
  }

  span {
    border-right: ${(props) => (props.isSelected ? '1px solid #006EB8' : 'none')};
  }
`

const CustomButtonGroup = ({
  selectedItem,
  onClick,
  buttonValues,
}: {
  selectedItem: string
  onClick: (e: any) => void
  buttonValues: {
    value: string
    label: string
  }[]
}) => {
  return (
    <ButtonGroup disableElevation color='primary'>
      {buttonValues.map((el) => (
        <CustomButton
          key={el.label + '-' + el.value}
          value={el.value}
          isSelected={selectedItem === el.value}
          onClick={(e) => onClick(e)}
        >
          {el.label}
        </CustomButton>
      ))}
    </ButtonGroup>
  )
}

export { CustomButtonGroup }
