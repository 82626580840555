import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { CalendarMonthIcon } from 'shared/assets'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
const minDate = dayjs('01/01/2000')

const CustomDatePicker = ({
  label,
  handleChange,
  value,
}: {
  label: string
  handleChange: (value: string) => void
  value: string | null | Dayjs
}) => {
  const [open, setOpen] = React.useState(false)

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault()
      e?.stopPropagation()
    }
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format={'DD MMM, YYYY'}
        slotProps={{
          textField: {
            onClick: () => setOpen(true),
            onBeforeInput: disableKeyboardEntry,
            size: 'small',
            placeholder: 'DD/MM/YYYY',
          },
        }}
        slots={{
          openPickerIcon: CalendarMonthIcon,
        }}
        onChange={(newValue) => handleChange(dayjs(newValue).toISOString())}
        defaultValue={null}
        value={value && dayjs.utc(value)}
        label={label}
        minDate={minDate}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        disableFuture
      />
    </LocalizationProvider>
  )
}

export { CustomDatePicker }
