import * as fetch from 'app/api/fetch'

export interface VehicleHistoryReq {
  req: string
  par: ParVehicleHistoryReq
}

export interface TripReq {
  req: string
  par: ParTripReq
}

export interface VehicleHistoryResRaw {
  req: string
  par: string
  user: string
}

export interface TripResRaw {
  req: string
  par: string
}

export interface ITripDetails {
  st: string
  ms: string
  a: number
  d: number
  sp: number
  o: number
  speeding?: string | number
}

export interface TripRes {
  req: string
  par: ITripDetails[]
}

export interface VehicleHistoryRes {
  req: string
  par: ParVehicleHistoryRes[]
  user: string
}

export interface VehicleHistoryTripsResRaw {
  par: string
  req: string
  selweek: string
  weeks: string
}

export type TripItem = {
  StartDate: number
  Distance: string
  ID: number
  CO2: string
  tripTime: string
  averageSpeed: string
  EndDate: number
  maxSpeed: string
  driver: string
  fuelConsumed: string
  longitude: number
  latitude: number
  StartPoint: string
  radius: number
}

export interface VehicleHistoryTripsRes {
  par: TripItem[]
  req: string
  selweek: string
  weeks: string[]
}

export interface ParVehicleHistoryReq {
  idvehicle: string
  ntrips: number
  week?: string
}

export interface ParTripReq {
  idtrip: string
  ntrips: number
}

export interface ParVehicleHistoryRes {
  Distance: number
  latitude: number
  StartDate: number
  longitude: number
  StartPoint: string
  ID: number
}

export const getVehicleHistory = async (idvehicle: string, ntrips: number): Promise<VehicleHistoryRes> => {
  try {
    const response = await fetch.post<VehicleHistoryReq, VehicleHistoryResRaw>({
      req: 'data',
      par: { idvehicle, ntrips: ntrips },
    })
    return {
      par: JSON.parse(response.par) as ParVehicleHistoryRes[],
      req: response.req,
      user: response.user,
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

export const getVehicleHistoryTrips = async (
  idvehicle: string,
  ntrips: number,
  week?: string
): Promise<VehicleHistoryTripsRes> => {
  try {
    const requestObj = {
      req: 'trips',
      par: { idvehicle, ntrips: ntrips, ...(week ? { week: week } : {}) },
    }
    const response = await fetch.post<VehicleHistoryReq, VehicleHistoryTripsResRaw>(requestObj)
    return {
      ...response,
      par: JSON.parse(response.par),
      weeks: JSON.parse(response.weeks),
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

export const getVehicleTrip = async (idtrip: string, ntrips: number): Promise<TripRes> => {
  try {
    const response = await fetch.post<TripReq, TripResRaw>({
      req: 'logs',
      par: { idtrip, ntrips: ntrips },
    })
    return {
      ...response,
      par: JSON.parse(response.par),
    }
  } catch (error: any) {
    throw new Error(error)
  }
}
