import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { SettingsBlackIcon, SettingsIcon } from 'shared/assets'
import { Switch, TextField, useTheme } from '@mui/material'
import { ReactNode } from 'react'

const SettingsModalItem = ({ title, desc, children }: { title: string; desc: string; children: ReactNode }) => {
  const {
    palette: { colorsGreyblueGrey50, textPrimary },
  } = useTheme()
  return (
    <Box sx={{ pl: 6, pr: 6 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${colorsGreyblueGrey50}`,
          pt: 6,
          pb: 6,
        }}
      >
        <Box>
          <Typography variant='body1' color={textPrimary}>
            {title}
          </Typography>
          <Typography variant='body2'>{desc}</Typography>
        </Box>
        <Box sx={{ maxWidth: 176 }}>{children}</Box>
      </Box>
    </Box>
  )
}

export const SettingsModal = () => {
  const {
    palette: { colorsGreyblueGrey50, textPrimary },
  } = useTheme()
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button
        onClick={handleOpen}
        sx={{ background: 'rgba(255, 255, 255, 0.10)', minWidth: 'auto', mr: 2, height: 40 }}
      >
        <SettingsIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 536,
            bgcolor: '#fff',
            borderRadius: '8px',
            boxShadow:
              '0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: `1px solid ${colorsGreyblueGrey50}`, p: 6 }}>
            <Box
              sx={{
                border: `1px solid ${colorsGreyblueGrey50}`,
                borderRadius: '8px',
                p: 3,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 4,
              }}
            >
              <SettingsBlackIcon />
            </Box>
            <Box>
              <Typography variant='h6'>Settings</Typography>
              <Typography variant='body2'>Lorem ipsum dolor sit amet consectetur</Typography>
            </Box>
          </Box>
          <SettingsModalItem
            title='Zip code for distance calculation'
            desc='Adjust settings to see the most accurate data'
          >
            <TextField name='zip' variant='outlined' label='Zip code' />
          </SettingsModalItem>
          <SettingsModalItem title='Merge Trips by day' desc='Lorem ipsum dolor sit amet consectetur'>
            <Switch defaultChecked />
          </SettingsModalItem>
          <SettingsModalItem title='Show License Plates' desc='Lorem ipsum dolor sit amet consectetur'>
            <Switch defaultChecked />
          </SettingsModalItem>

          <Box sx={{ display: 'flex', alignItems: 'center', borderTop: `1px solid ${colorsGreyblueGrey50}`, p: 6 }}>
            <Box sx={{ width: '50%', mr: 1 }}>
              <Button
                onClick={handleClose}
                variant='outlined'
                color='secondary'
                sx={{ width: '100%', fontSize: '16px' }}
              >
                Cancel
              </Button>
            </Box>
            <Box sx={{ width: '50%', ml: 1 }}>
              <Button onClick={handleClose} sx={{ width: '100%' }} variant='contained'>
                Change settings
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
