import { ReactNode } from 'react'
import { Header } from '../header/Header'
import { Box, useTheme } from '@mui/material'

interface PageLayoutProps {
  children: ReactNode
}

const PageLayout = ({ children }: PageLayoutProps) => {
  const {
    palette: { grey50 },
  } = useTheme()
  return (
    <Box sx={{ background: grey50, minHeight: '100vh' }}>
      <Header />
      <Box
        sx={{
          padding: '24px 80px 40px 80px',
          maxWidth: 1440,
          margin: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export { PageLayout }
