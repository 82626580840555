const CarIcon = ({ color = '#565673' }: { color?: string }) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='directions_car_FILL0_wght300_GRAD0_opsz24 1'>
        <path
          id='Vector'
          d='M4.67976 15.4163V16.2224C4.67976 16.4628 4.59644 16.6663 4.42978 16.833C4.26312 16.9996 4.05234 17.083 3.79745 17.083C3.55235 17.083 3.34434 16.9973 3.17341 16.8259C3.00246 16.6545 2.91699 16.4464 2.91699 16.2016V10.0638L4.61893 5.20801C4.68089 5.01677 4.79467 4.8648 4.96026 4.75209C5.12586 4.63937 5.31015 4.58301 5.51314 4.58301H14.5195C14.7145 4.58301 14.8916 4.6403 15.0507 4.75488C15.2098 4.86947 15.3201 5.02051 15.3817 5.20801L17.0836 10.0638V16.2224C17.0836 16.4628 17.0003 16.6663 16.8336 16.833C16.667 16.9996 16.4562 17.083 16.2013 17.083C15.9562 17.083 15.7482 16.9973 15.5772 16.8259C15.4063 16.6545 15.3208 16.4464 15.3208 16.2016V15.4163H4.67976ZM4.67337 8.8138H15.3272L14.276 5.83299H5.72466L4.67337 8.8138ZM6.22014 13.2048C6.52336 13.2048 6.78047 13.0986 6.99147 12.8864C7.20249 12.6741 7.30799 12.4164 7.30799 12.1132C7.30799 11.8099 7.20186 11.5528 6.9896 11.3418C6.77733 11.1308 6.51959 11.0253 6.21637 11.0253C5.91315 11.0253 5.65603 11.1314 5.44501 11.3437C5.23401 11.556 5.12851 11.8137 5.12851 12.1169C5.12851 12.4201 5.23465 12.6773 5.44691 12.8883C5.65917 13.0993 5.91692 13.2048 6.22014 13.2048ZM13.7842 13.2048C14.0875 13.2048 14.3446 13.0986 14.5556 12.8864C14.7666 12.6741 14.8721 12.4164 14.8721 12.1132C14.8721 11.8099 14.766 11.5528 14.5537 11.3418C14.3414 11.1308 14.0837 11.0253 13.7805 11.0253C13.4773 11.0253 13.2201 11.1314 13.0091 11.3437C12.7981 11.556 12.6926 11.8137 12.6926 12.1169C12.6926 12.4201 12.7987 12.6773 13.011 12.8883C13.2233 13.0993 13.481 13.2048 13.7842 13.2048ZM4.16697 14.1663H15.8336V10.0638H4.16697V14.1663Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export { CarIcon }
