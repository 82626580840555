const DocumentIcon = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='description_FILL0_wght300_GRAD0_opsz48 1'>
        <path
          id='Vector'
          d='M16.4692 35.1346H31.5307V32.8654H16.4692V35.1346ZM16.4692 26.7692H31.5307V24.5H16.4692V26.7692ZM11.8846 42.9999C11.1003 42.9999 10.4235 42.7152 9.8541 42.1458C9.2847 41.5764 9 40.8996 9 40.1153V7.88465C9 7.10035 9.2847 6.4235 9.8541 5.8541C10.4235 5.2847 11.1003 5 11.8846 5H29.2615L38.9999 14.7384V40.1153C38.9999 40.8996 38.7152 41.5764 38.1458 42.1458C37.5764 42.7152 36.8996 42.9999 36.1153 42.9999H11.8846ZM28.1269 15.7615V7.2692H11.8846C11.7308 7.2692 11.5897 7.3333 11.4615 7.4615C11.3333 7.58974 11.2692 7.73078 11.2692 7.88465V40.1153C11.2692 40.2691 11.3333 40.4102 11.4615 40.5384C11.5897 40.6666 11.7308 40.7307 11.8846 40.7307H36.1153C36.2691 40.7307 36.4102 40.6666 36.5384 40.5384C36.6666 40.4102 36.7307 40.2691 36.7307 40.1153V15.7615H28.1269Z'
          fill='#78788F'
        />
      </g>
    </svg>
  )
}

export { DocumentIcon }
