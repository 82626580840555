import { createSlice } from '@reduxjs/toolkit'
import { loginUser } from '../actions/loginActions'
import { ReduxError } from 'app/api/types'
import { userIdSessionStorageKey, userNameSessionStorageKey } from 'shared/utils'

interface LoginSliceState {
  isLoading: boolean
  error?: ReduxError
}

const initialState: LoginSliceState = {
  isLoading: false,
  error: { message: '' },
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.isLoading = false
      sessionStorage.setItem(userNameSessionStorageKey, JSON.stringify(payload.userName))
      sessionStorage.setItem(userIdSessionStorageKey, JSON.stringify(payload.res.uid))
    })
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
  },
})

export default loginSlice.reducer
