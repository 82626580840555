const LogoutIcon = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='logout_FILL0_wght300_GRAD0_opsz24 1'>
        <path
          id='Vector'
          d='M5.40387 21C4.89875 21 4.47119 20.825 4.12119 20.475C3.77119 20.125 3.59619 19.6974 3.59619 19.1923V5.3077C3.59619 4.80257 3.77119 4.375 4.12119 4.025C4.47119 3.675 4.89875 3.5 5.40387 3.5H12.1154V4.99998H5.40387C5.32693 4.99998 5.25642 5.03203 5.19232 5.09613C5.1282 5.16024 5.09614 5.23077 5.09614 5.3077V19.1923C5.09614 19.2692 5.1282 19.3398 5.19232 19.4039C5.25642 19.468 5.32693 19.5 5.40387 19.5H12.1154V21H5.40387ZM16.1346 16.5192L15.0962 15.4346L17.5308 13H9.11539V11.5H17.5308L15.0962 9.0654L16.1346 7.98083L20.4038 12.25L16.1346 16.5192Z'
          fill='#565673'
        />
      </g>
    </svg>
  )
}

export { LogoutIcon }
