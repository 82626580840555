import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { DashboardGraphRes, getDashboardGraph } from 'features/dashboard/api/services/dashboardOverallService'
import { logoutUser } from 'shared/features/logout/action/logoutActions'

export const getDashboardGraphAction = createAsyncThunk<DashboardGraphRes, null, { rejectValue: ReduxError }>(
  'getDashboardGraph',
  async (_, thunkApi) => {
    const response = await getDashboardGraph()
      .then((res) => {
        if (res.req !== 'dashboardGraph') {
          return thunkApi.rejectWithValue({
            message: 'error',
          })
        } else if (res.login == 'login') {
          thunkApi.dispatch(logoutUser(null))
          return res
        } else {
          return res
        }
      })
      .catch((err) => {
        const { message } = err
        return thunkApi.rejectWithValue({
          message: message,
        })
      })
    return response
  }
)
