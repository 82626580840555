import { Box, Typography } from '@mui/material'
import { StarsIcon } from 'shared/assets/icons/StarsIcon'
import { formatLicenseId } from '../../utils/stringUtils'

interface LicensePlateProps {
  body: string
}

const LicensePlate = ({ body }: LicensePlateProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
        border: '2px solid #005085',
        background: '#F4BE04',
        borderRadius: 1,
        maxHeight: 32,
        maxWidth: '140px',
      }}
    >
      <Box
        sx={{
          background: '#005085',
          p: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pt: 2,
          pb: 2,
        }}
      >
        <StarsIcon />
      </Box>

      <Box sx={{ width: '100%', fontSize: '12px', color: '#002F4F' }}>
        <Typography sx={{ fontWeight: 700 }}>{formatLicenseId(body)}</Typography>
      </Box>
    </Box>
  )
}

export { LicensePlate }
