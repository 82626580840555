import { ReactNode } from 'react'
import { FullPageSpinner } from 'shared/components'

interface LoaderWrapperProps {
  isLoading: boolean
  children: ReactNode
}

const FullPageLoaderWrapper = ({ isLoading, children }: LoaderWrapperProps) => {
  return <>{isLoading ? <FullPageSpinner /> : children}</>
}

export { FullPageLoaderWrapper }
