import { Box } from '@mui/material'
import { CircularUnderLoad } from './CircularUnderLoad'

const FullPageSpinner = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
      }}
    >
      <CircularUnderLoad />
    </Box>
  )
}

export { FullPageSpinner }
