import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from 'reportWebVitals'
import './index.css'
import 'leaflet/dist/leaflet.css'
import App from 'app/App'
import L from 'leaflet'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'

L.Marker.prototype.options.icon = L.icon({
  iconUrl: '/assets/map-vehicle-marker.svg',
  shadowUrl: iconShadow,
})

dayjs.extend(isBetween)
dayjs.extend(utc)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

reportWebVitals()
