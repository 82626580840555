import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { logoutUser } from 'shared/features/logout/action/logoutActions'
import { LogoutIcon } from 'shared/assets'
import { selectUserName } from 'shared/features'
import { Typography } from '@mui/material'
import { colors } from 'app/utils/theme/utils/colors'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}))

export const UserProfileDropdown = () => {
  const dispatch = useAppDispatch()
  const userName = JSON.parse(useAppSelector(selectUserName))

  const logout = async () => {
    await dispatch(logoutUser(null))
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        id='userProfileDropdownbutton'
        sx={{ color: colors.primaryContrast, boxShadow: 'none' }}
        aria-controls={open ? 'userProfileDropdownmenu' : undefined}
        variant='text'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {userName}
      </Button>
      <StyledMenu
        id='userProfileDropdownmenu'
        MenuListProps={{
          'aria-labelledby': 'userProfileDropdownbutton',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={logout} sx={{ padding: 3 }} disableRipple>
          <LogoutIcon />
          <Typography sx={{ ml: 4 }} variant='body2'>
            Sign out
          </Typography>
        </MenuItem>
      </StyledMenu>
    </div>
  )
}
