import * as fetch from 'app/api/fetch'
import { TotalRes, TotalResRaw, MonthSelectType } from './types'

export interface DashboardMonthlySummaryParItem {
  kmsDifference: string
  type: MonthSelectType
  fuelDifference: string
  tripsDifference: string
  fuelConsumed: number
  co2Difference: string
  sumOfKms: number
  co2Emission: number
  numberOfTrips: number
}

export interface DashboardMonthlySummaryRes extends TotalRes {
  req: string
  par: DashboardMonthlySummaryParItem[]
  user: string
}

export const getDashboardMonthlySummary = async (): Promise<DashboardMonthlySummaryRes> => {
  try {
    const response = await fetch.post<{ req: string; par: object }, TotalResRaw>({
      req: 'dashboardMonthlySummary',
      par: {},
    })

    return {
      ...response,
      par: JSON.parse(response.par) as DashboardMonthlySummaryParItem[],
    }
  } catch (error: any) {
    throw new Error(error)
  }
}
