import { CSSInterpolation } from '@mui/material'
import { colors } from './colors'

const { backgroundPaper, colorsGreyblueGrey50 } = colors

export const customStylesMuiPaper: CSSInterpolation = {
  borderRadius: '8px',
  background: backgroundPaper,
  border: `1px solid ${colorsGreyblueGrey50}`,
  boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.04)',
  padding: '24px 24px 32px 24px',
}
