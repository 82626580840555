import { PaletteColor, PaletteColorOptions, PaletteOptions, SimplePaletteColorOptions } from '@mui/material'
import { colors } from './colors'
declare module '@mui/material/styles' {
  export interface Palette {
    primaryMain: string
    primaryContrast: string
    textPrimary: string
    textSecondary: string
    blue900: string
    backgroundPaper: string
    colorsGreyblueGrey50: string
    grey400: string
    grey50: string
    green700: string
    green800: string
    blue300: string
    orange500: string
    red500: string
  }

  export interface PaletteOptions {
    primaryMain: string
    primaryContrast: string
    textPrimary: string
    textSecondary: string
    blue900: string
    backgroundPaper: string
    colorsGreyblueGrey50: string
    grey400: string
    grey50: string
    green700: string
    green800: string
    blue300: string
    orange500: string
    red500: string
  }
}

const { primaryMain, primaryContrast } = colors

export const customPalette: PaletteOptions = {
  primary: {
    main: primaryMain,
    contrastText: primaryContrast,
  },
  secondary: {
    main: primaryMain,
    contrastText: primaryContrast,
  },
  // Provide every color token (light, main, dark, and contrastText) when using
  // custom colors for props in Material UI's components.
  // Then you will be able to use it like this: `<Button color="custom">`
  // (For TypeScript, you need to add module augmentation for the `custom` value)
  ...colors,
  // Used by `getContrastText()` to maximize the contrast between
  // the background and the text.
  contrastThreshold: 3,
  // Used by the functions below to shift a color's luminance by approximately
  // two indexes within its tonal palette.
  // E.g., shift from Red 500 to Red 300 or Red 700.
  tonalOffset: 0.2,
}
