const DoneIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Icon Left'>
        <path
          id='Vector'
          d='M5.58294 14.7112L1.16309 10.2914L2.05409 9.4132L5.59575 12.9549L5.88425 12.6664L6.76242 13.5446L5.58294 14.7112ZM10.2913 14.7112L5.87142 10.2914L6.74961 9.40039L10.2913 12.9421L17.9579 5.27539L18.8361 6.16639L10.2913 14.7112ZM10.0028 10.2914L9.11179 9.4132L13.2368 5.2882L14.1278 6.16639L10.0028 10.2914Z'
          fill='#006EB8'
        />
      </g>
    </svg>
  )
}

export { DoneIcon }
