import { ParVehiclesRes } from 'features/vehiclesList/api/services/vehiclesListService'

export type SortOrder = 'asc' | 'desc'

export function sortArrayByField<T extends ParVehiclesRes>(arr: T[], field: keyof T, sortOrder: SortOrder = 'asc') {
  arr.sort((a, b) => {
    let comparison = 0

    if (a[field] < b[field]) {
      comparison = -1
    } else if (a[field] > b[field]) {
      comparison = 1
    }

    // Reverse the comparison if sortOrder is 'desc'
    if (sortOrder === 'desc') {
      comparison *= -1
    }

    return comparison
  })

  return arr
}
