import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { ParVehicleHistoryRes, TripItem, ITripDetails } from '../../api/services/vehiclesHistoryService'
import { getVehicleHistoryAction, getAllVehicleHistoryTrips, getTripDetails } from '../actions/vehicleHistoryActions'
import { ParVehiclesRes } from '../../api/services/vehiclesListService'
import { sortBy as _sortBy } from 'lodash'

export type VehicleTrips = {
  trips: TripItem[]
  selweek: string
  weeks: { label: string; value: string }[]
  originalWeeks: string[]
}

interface VehicleHistorySliceState {
  vehicleHistory: ParVehicleHistoryRes[]
  vehicleHistoryTrips: VehicleTrips
  selectedVehicle: ParVehiclesRes | null
  selectedTrip: TripItem | null
  selectedTripDetails: ITripDetails[] | null
  tripSummarySettings: {
    isShowGpsPoints: boolean
    isShowSpeeding: boolean
  }
  isLoading: boolean
  isLoadingTripDetails: boolean
  error?: ReduxError
}

const initialState: VehicleHistorySliceState = {
  vehicleHistory: [],
  vehicleHistoryTrips: {
    trips: [],
    selweek: '',
    originalWeeks: [],
    weeks: [],
  },
  selectedTrip: null,
  selectedTripDetails: null,
  tripSummarySettings: {
    isShowGpsPoints: false,
    isShowSpeeding: false,
  },
  selectedVehicle: null,
  isLoading: false,
  isLoadingTripDetails: false,
  error: { message: '' },
}

export const vehicleHistorySlice = createSlice({
  name: 'vehicleHistory',
  initialState,
  reducers: {
    setSelectedVehicle: (state, action: PayloadAction<ParVehiclesRes>) => {
      state.selectedVehicle = action.payload
    },
    clearSelectedVehicle: (state) => {
      state.selectedVehicle = null
    },
    setSelectedTrip: (state, action: PayloadAction<TripItem>) => {
      state.selectedTrip = action.payload
    },
    clearSelectedTrip: (state) => {
      state.selectedTrip = null
    },
    clearTrips: (state) => {
      state.vehicleHistoryTrips.trips = []
    },
    setGpsPoints: (state, action: PayloadAction<boolean>) => {
      state.tripSummarySettings.isShowGpsPoints = action.payload
    },
    setSpeeding: (state, action: PayloadAction<boolean>) => {
      state.tripSummarySettings.isShowSpeeding = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleHistoryAction.pending, (state) => {
      state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(getVehicleHistoryAction.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.vehicleHistory = payload.par
    })
    builder.addCase(getVehicleHistoryAction.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
    builder.addCase(getAllVehicleHistoryTrips.pending, (state) => {
      state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(getAllVehicleHistoryTrips.fulfilled, (state, { payload }) => {
      let uniqWeeks = state.vehicleHistoryTrips.originalWeeks
      if (payload.weeks?.length) {
        state.vehicleHistoryTrips.originalWeeks = payload.weeks
        uniqWeeks = payload.weeks
      }
      state.isLoading = false
      state.vehicleHistoryTrips.trips = payload.par
      state.vehicleHistoryTrips.selweek = payload.selweek
      state.vehicleHistoryTrips.weeks = [
        ..._sortBy(uniqWeeks?.map((i: string) => ({ label: i, value: i })) || [], 'value'),
      ]
    })
    builder.addCase(getAllVehicleHistoryTrips.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
    builder.addCase(getTripDetails.pending, (state) => {
      state.isLoadingTripDetails = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(getTripDetails.fulfilled, (state, { payload }) => {
      state.isLoadingTripDetails = false
      state.selectedTripDetails = payload.par
    })
    builder.addCase(getTripDetails.rejected, (state, { payload }) => {
      state.isLoadingTripDetails = false
      state.error = {
        message: payload?.message,
      }
    })
  },
})

export const {
  setSelectedVehicle,
  clearSelectedVehicle,
  setSelectedTrip,
  clearSelectedTrip,
  setGpsPoints,
  setSpeeding,
  clearTrips,
} = vehicleHistorySlice.actions

export default vehicleHistorySlice.reducer
