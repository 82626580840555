import { Box, useTheme } from '@mui/system'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const DateFormatedToText = ({ date }: { date: number }) => {
  const [color, setColor] = useState('')
  const [text, setText] = useState('')
  const {
    palette: { green700, green800, blue300, orange500, red500 },
  } = useTheme()

  useEffect(() => {
    const currentDate = new Date()
    const inputDate = new Date(dayjs.unix(date).format())

    // Calculate the difference in days
    const timeDiff = Math.abs(currentDate.getTime() - inputDate.getTime())
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))
    const diffMinutes = Math.ceil(timeDiff / (1000 * 3600))

    if (diffDays === 0) {
      // Today
      setColor(green700)
      setText(`${diffMinutes} minutes ago`)
    } else if (diffDays === 1) {
      // Yesterday
      setColor(green800)
      setText('yesterday')
    } else if (diffDays <= 7) {
      // Within 7 days
      setColor(blue300)
      setText('This week')
    } else if (diffDays > 7 && diffDays <= 30) {
      // Over 7 days but within a month
      setColor(orange500)
      setText('This month')
    } else if (_.isNaN(Math.floor(diffDays / 30))) {
      // No data available
      setColor(red500)
      setText('No data available')
    } else {
      // Over a month
      setColor(red500)
      setText(`${Math.floor(diffDays / 30)} Month(s) ago`)
    }
  }, [date])

  return (
    <Box component={'span'} sx={{ color: color }}>
      {text}
    </Box>
  )
}

export { DateFormatedToText }
