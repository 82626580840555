import { createSlice } from '@reduxjs/toolkit'
import { readUserFromSessionStorage } from 'shared/features'
import { userIdSessionStorageKey, userNameSessionStorageKey } from 'shared/utils'

interface AuthSliceState {
  userId: string
  userName: string
  isAuthenticated: boolean
  error: {
    message: string
  }
}

const initialState: AuthSliceState = {
  userId: '',
  userName: '',
  isAuthenticated: false,
  error: {
    message: '',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }: { payload: { uname: string; uid: string } }) => {
      sessionStorage.setItem(userIdSessionStorageKey, JSON.stringify(payload.uid))
      sessionStorage.setItem(userNameSessionStorageKey, JSON.stringify(payload.uname))
      state.userId = payload.uid
      state.userName = JSON.stringify(payload.uname)
    },
    setAuthenticated: (state, { payload }: { payload: boolean }) => {
      state.isAuthenticated = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(readUserFromSessionStorage.pending, (state) => {
      state.error = {
        message: '',
      }
    })
    builder.addCase(readUserFromSessionStorage.fulfilled, (state, { payload }) => {
      state.userId = payload ? payload.uid! : ''
      state.userName = payload ? payload.uname! : ''
    })
    builder.addCase(readUserFromSessionStorage.rejected, (state, { payload }) => {
      state.error = {
        message: 'readUserFromSessionStorage error',
      }
    })
  },
})

export const authReducer = authSlice.reducer
