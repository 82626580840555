import { createSlice } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { DashboardGraphParItem } from 'features/dashboard/api/services/dashboardOverallService'
import { getDashboardGraphAction } from '../actions/dashboardOverallActions'

interface VehicleHistorySliceState {
  chartData: DashboardGraphParItem[]
  isLoading: boolean
  error?: ReduxError
}

const initialState: VehicleHistorySliceState = {
  chartData: [],
  isLoading: false,
  error: { message: '' },
}

export const dashboardOverallSlice = createSlice({
  name: 'dashboardOverallSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardGraphAction.pending, (state) => {
      state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(getDashboardGraphAction.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.chartData = payload.par
    })
    builder.addCase(getDashboardGraphAction.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
  },
})

export default dashboardOverallSlice.reducer
