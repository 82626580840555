import { userIdSessionStorageKey, userNameSessionStorageKey } from 'shared/utils'
import { authSlice } from '../slice/authSlice'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const readUserFromSessionStorage = createAsyncThunk('auth/readUser', async (_, { rejectWithValue }) => {
  try {
    const uid = sessionStorage.getItem(userIdSessionStorageKey)
    const uname = sessionStorage.getItem(userNameSessionStorageKey)
    return {
      uid,
      uname,
    }
  } catch (error: any) {
    return rejectWithValue(error.message)
  }
})

export const { setUser, setAuthenticated } = authSlice.actions
