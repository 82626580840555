import { Box, Typography } from '@mui/material'
import { colors } from 'app/utils/theme/utils/colors'
import { Navbar } from '../navbar/Navbar'
import { Logo } from 'shared/assets'
import { UserProfileDropdown } from './UserProfileDropdown'
import { SettingsModal } from 'shared/features'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <Box sx={{ p: 8, backgroundColor: colors.blue900, display: 'flex', justifyContent: 'space-between' }}>
        <Link
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          to='/'
        >
          <Logo />
        </Link>
        <Box style={{ display: 'flex' }}>
          <Box
            component={'a'}
            href='https://tdsl.nl/'
            target='_blank'
            display='flex'
            alignItems='center'
            mr={6}
            sx={{
              borderRadius: 2,
              border: '2px solid #006EB8',
              padding: '6px 16px',
              background: 'rgba(255, 255, 255, 0.10)',
            }}
          >
            <Typography variant='body1' color={'#fff'}>
              Visit TDSL
            </Typography>
          </Box>
          <SettingsModal />
          <UserProfileDropdown />
        </Box>
      </Box>
      <Navbar />
    </>
  )
}

export { Header }
