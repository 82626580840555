const TrendingDownIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      style={{ transform: 'rotate(75deg)' }}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='trending_up_FILL0_wght300_GRAD0_opsz24 2'>
        <path
          id='Vector'
          d='M1.88204 11.4803C1.78638 11.3847 1.73963 11.2698 1.74177 11.1356C1.7439 11.0014 1.79112 10.8826 1.88342 10.7792L5.8129 6.81637C5.87103 6.76081 5.93549 6.71851 6.00629 6.68945C6.07708 6.66039 6.15293 6.64585 6.23384 6.64585C6.31475 6.64585 6.39153 6.66039 6.46419 6.68945C6.53683 6.71851 6.60148 6.76136 6.65814 6.818L8.90137 9.06125L12.7783 5.23045H11.3014C11.1597 5.23045 11.041 5.18251 10.9451 5.08664C10.8493 4.99076 10.8014 4.87196 10.8014 4.73024C10.8014 4.58851 10.8493 4.46979 10.9451 4.37405C11.041 4.27833 11.1597 4.23047 11.3014 4.23047H13.8655C14.0362 4.23047 14.1793 4.28821 14.2948 4.4037C14.4103 4.51919 14.468 4.6623 14.468 4.83304V7.39712C14.468 7.53879 14.4201 7.65754 14.3242 7.75337C14.2283 7.8492 14.1095 7.89712 13.9678 7.89712C13.8261 7.89712 13.7074 7.8492 13.6116 7.75337C13.5159 7.65754 13.4681 7.53879 13.4681 7.39712V5.933L9.32315 10.0779C9.26076 10.1403 9.19467 10.1843 9.12487 10.2099C9.05507 10.2356 8.98029 10.2484 8.90052 10.2484C8.82074 10.2484 8.74624 10.2356 8.677 10.2099C8.60778 10.1843 8.54197 10.1403 8.47957 10.0779L6.2347 7.833L2.5693 11.4984C2.47879 11.5907 2.3677 11.6369 2.23604 11.6369C2.10438 11.6369 1.98638 11.5847 1.88204 11.4803Z'
          fill='#43A75A'
        />
      </g>
    </svg>
  )
}

export { TrendingDownIcon }
