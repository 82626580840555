import * as fetch from 'app/api/fetch'

export interface VehiclesReq {
  req: string
  par: ParVehiclesReq
}

export interface VehiclesResRaw {
  req: string
  par: string
  user: string
}

export interface VehiclesRes {
  req: string
  par: ParVehiclesRes[]
  user: string
  login?: string
}

export interface ParVehiclesReq {
  vehicle: string
}

export interface ParVehiclesRes {
  engtemp: number
  desc: string
  fuell: string
  gcode: number
  boxtype: string
  idvehicle: string
  gpiop: string
  gmttime: number
  km: number
  gvalue: number
  garmin: number
  fueld: string
  speed: number
  gpsfix: boolean
  apponly: number
  gspeed: number
  active: number
  direction: number
  imei: string
  fuel: number
  fuelLiters: number
  longitude: number
  fuelp: string
  id: string
  latitude: number
  doors: string
  radius: number
  driver: string
  group: string
  lastTrip: number
  lastInfo: number
  isSelected: boolean
}

export const getAllVehicles = async (): Promise<VehiclesRes> => {
  try {
    const response = await fetch.post<VehiclesReq, VehiclesResRaw>({ req: 'data', par: { vehicle: 'all' } })
    return {
      par: JSON.parse(response.par) as ParVehiclesRes[],
      req: response.req,
      user: response.user,
    }
  } catch (error: any) {
    throw new Error(error)
  }
}
