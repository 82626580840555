import * as fetch from 'app/api/fetch'

export interface ErrorMsg {
  msg: string
}
export interface LoginReq {
  req: string
  par: ParLoginReq
}

export interface LoginRes {
  login: string
  uid: string
  error?: ErrorMsg
}

export interface ParLoginReq {
  uname: string
  paswd: string
}

export const login = async (par: ParLoginReq): Promise<LoginRes> => {
  try {
    const response = await fetch.post<LoginReq, LoginRes>({ req: 'login', par })
    return response
  } catch (error: any) {
    throw new Error(error)
  }
}
