import { createSlice } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { getDashboardMonthlySummaryAction } from '../actions/dashboardTotalActions'
import { DashboardMonthlySummaryParItem } from 'features/dashboard/api/services/dashboardTotalSerivces'

interface DashboardTotalSliceState {
  isLoading: boolean
  monthlySummary: DashboardMonthlySummaryParItem[]
  error?: ReduxError
}

const initialState: DashboardTotalSliceState = {
  isLoading: false,
  monthlySummary: [],
  error: { message: '' },
}

export const dashboardTotalSlice = createSlice({
  name: 'dashboardTotal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardMonthlySummaryAction.pending, (state) => {
      state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(getDashboardMonthlySummaryAction.fulfilled, (state, { payload }) => {
      state.isLoading = false
      state.monthlySummary = payload.par
    })
    builder.addCase(getDashboardMonthlySummaryAction.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
  },
})

export default dashboardTotalSlice.reducer
