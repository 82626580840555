import { Box } from '@mui/material'
import dayjs from 'dayjs'

const DateHour = ({ date }: { date: number }) => {
  const formattedDate = dayjs.unix(date).format('DD/MM/YYYY')
  const formattedTime = dayjs.unix(date).utc().format('HH:mm:ss')
  if (date) {
    return (
      <Box>
        <Box>{formattedDate}</Box>
        <Box>{formattedTime}</Box>
      </Box>
    )
  } else {
    return <></>
  }
}

export { DateHour }
