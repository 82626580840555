import { ParVehiclesRes } from 'features/vehiclesList/api/services/vehiclesListService'

const stringSortPrefixes = [
  { label: 'a-z', direction: 'asc' },
  { label: 'z-a', direction: 'desc' },
]
const numberSortPrefixes = [
  { label: '0-∞', direction: 'asc' },
  { label: '∞-0', direction: 'desc' },
]
const dateSortPrefixes = [
  { label: 'asc', direction: 'asc' },
  { label: 'desc', direction: 'desc' },
]

interface VehicleListRow {
  name: string
  field: keyof ParVehiclesRes
  sortPrefixes: {
    label: string
    direction: string
  }[]
}

export const vehicleListRows: VehicleListRow[] = [
  { name: 'License', field: 'id', sortPrefixes: stringSortPrefixes },
  { name: 'Description', field: 'desc', sortPrefixes: stringSortPrefixes },
  { name: 'Fuel level', field: 'fuel', sortPrefixes: numberSortPrefixes },
  { name: 'Last info', field: 'lastInfo', sortPrefixes: dateSortPrefixes },
  { name: 'Last trip', field: 'lastTrip', sortPrefixes: dateSortPrefixes },
  { name: 'Group', field: 'group', sortPrefixes: stringSortPrefixes },
  { name: 'Driver', field: 'driver', sortPrefixes: stringSortPrefixes },
]
