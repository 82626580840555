const ArrowIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='arrow_forward_FILL0_wght300_GRAD0_opsz24 2'>
        <path
          id='Vector'
          d='M10.0004 16.3465L9.10942 15.4684L13.9524 10.6254H3.6543V9.37544H13.9524L9.10942 4.53248L10.0004 3.6543L16.3465 10.0004L10.0004 16.3465Z'
          fill='#006EB8'
        />
      </g>
    </svg>
  )
}

export { ArrowIcon }
