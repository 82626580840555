const LoginHeader = () => {
  return <div>xdd</div>
}

export { LoginHeader }
const StarsIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='European_stars 1' clipPath='url(#clip0_406_30268)'>
        <g id='s'>
          <g id='c'>
            <path id='t' d='M8 0.533203L7.67038 1.54766L8.17761 1.71247' fill='white' />
            <path id='t_2' d='M8 0.533203L8.32962 1.54766L7.82239 1.71247' fill='white' />
          </g>
          <g id='c_2'>
            <path id='t_3' d='M9.01416 1.27051L7.94749 1.27051L7.94749 1.80384' fill='white' />
            <path id='t_4' d='M9.01416 1.27051L8.15121 1.89748L7.83772 1.466' fill='white' />
          </g>
          <g id='c_3'>
            <path id='t_5' d='M8.62695 2.46289L8.29734 1.44843L7.7901 1.61324' fill='white' />
            <path id='t_6' d='M8.62695 2.46289L7.764 1.83592L8.07749 1.40444' fill='white' />
          </g>
          <g id='c_4'>
            <path id='t_7' d='M7.37305 2.46289L8.236 1.83592L7.92251 1.40444' fill='white' />
            <path id='t_8' d='M7.37305 2.46289L7.70266 1.44843L8.20989 1.61324' fill='white' />
          </g>
          <g id='c_5'>
            <path id='t_9' d='M6.98535 1.27051L7.8483 1.89748L8.16179 1.466' fill='white' />
            <path id='t_10' d='M6.98535 1.27051L8.05202 1.27051V1.80384' fill='white' />
          </g>
        </g>
        <g id='s_2'>
          <g id='c_6'>
            <path id='t_11' d='M11.2002 1.39062L10.8706 2.40509L11.3778 2.56989' fill='white' />
            <path id='t_12' d='M11.2002 1.39062L11.5298 2.40509L11.0226 2.56989' fill='white' />
          </g>
          <g id='c_7'>
            <path id='t_13' d='M12.2144 2.12793L11.1477 2.12793L11.1477 2.66126' fill='white' />
            <path id='t_14' d='M12.2144 2.12793L11.3514 2.7549L11.0379 2.32342' fill='white' />
          </g>
          <g id='c_8'>
            <path id='t_15' d='M11.8271 3.32031L11.4975 2.30585L10.9903 2.47066' fill='white' />
            <path id='t_16' d='M11.8271 3.32031L10.9642 2.69334L11.2777 2.26187' fill='white' />
          </g>
          <g id='c_9'>
            <path id='t_17' d='M10.5732 3.32031L11.4362 2.69334L11.1227 2.26187' fill='white' />
            <path id='t_18' d='M10.5732 3.32031L10.9029 2.30585L11.4101 2.47066' fill='white' />
          </g>
          <g id='c_10'>
            <path id='t_19' d='M10.1855 2.12793L11.0485 2.7549L11.362 2.32342' fill='white' />
            <path id='t_20' d='M10.1855 2.12793L11.2522 2.12793L11.2522 2.66126' fill='white' />
          </g>
        </g>
        <g id='s_3'>
          <g id='c_11'>
            <path id='t_21' d='M13.543 3.7334L13.2134 4.74786L13.7206 4.91267' fill='white' />
            <path id='t_22' d='M13.543 3.7334L13.8726 4.74786L13.3654 4.91267' fill='white' />
          </g>
          <g id='c_12'>
            <path id='t_23' d='M14.5571 4.4707L13.4905 4.4707L13.4905 5.00404' fill='white' />
            <path id='t_24' d='M14.5571 4.4707L13.6942 5.09767L13.3807 4.6662' fill='white' />
          </g>
          <g id='c_13'>
            <path id='t_25' d='M14.1699 5.66309L13.8403 4.64863L13.3331 4.81343' fill='white' />
            <path id='t_26' d='M14.1699 5.66309L13.307 5.03611L13.6205 4.60464' fill='white' />
          </g>
          <g id='c_14'>
            <path id='t_27' d='M12.916 5.66309L13.779 5.03611L13.4655 4.60464' fill='white' />
            <path id='t_28' d='M12.916 5.66309L13.2456 4.64863L13.7529 4.81343' fill='white' />
          </g>
          <g id='c_15'>
            <path id='t_29' d='M12.5283 4.4707L13.3913 5.09767L13.7048 4.6662' fill='white' />
            <path id='t_30' d='M12.5283 4.4707L13.595 4.4707L13.595 5.00404' fill='white' />
          </g>
        </g>
        <g id='s_4'>
          <g id='c_16'>
            <path id='t_31' d='M14.4004 6.93359L14.0708 7.94805L14.578 8.11286' fill='white' />
            <path id='t_32' d='M14.4004 6.93359L14.73 7.94805L14.2228 8.11286' fill='white' />
          </g>
          <g id='c_17'>
            <path id='t_33' d='M15.4146 7.6709L14.3479 7.6709L14.3479 8.20423' fill='white' />
            <path id='t_34' d='M15.4146 7.6709L14.5516 8.29787L14.2381 7.86639' fill='white' />
          </g>
          <g id='c_18'>
            <path id='t_35' d='M15.0273 8.86328L14.6977 7.84882L14.1905 8.01363' fill='white' />
            <path id='t_36' d='M15.0273 8.86328L14.1644 8.23631L14.4779 7.80483' fill='white' />
          </g>
          <g id='c_19'>
            <path id='t_37' d='M13.7734 8.86328L14.6364 8.23631L14.3229 7.80483' fill='white' />
            <path id='t_38' d='M13.7734 8.86328L14.1031 7.84882L14.6103 8.01363' fill='white' />
          </g>
          <g id='c_20'>
            <path id='t_39' d='M13.3857 7.6709L14.2487 8.29787L14.5622 7.86639' fill='white' />
            <path id='t_40' d='M13.3857 7.6709L14.4524 7.6709L14.4524 8.20423' fill='white' />
          </g>
        </g>
        <g id='s_5'>
          <g id='c_21'>
            <path id='t_41' d='M13.543 10.1338L13.2134 11.1482L13.7206 11.3131' fill='white' />
            <path id='t_42' d='M13.543 10.1338L13.8726 11.1482L13.3654 11.3131' fill='white' />
          </g>
          <g id='c_22'>
            <path id='t_43' d='M14.5571 10.8711L13.4905 10.8711L13.4905 11.4044' fill='white' />
            <path id='t_44' d='M14.5571 10.8711L13.6942 11.4981L13.3807 11.0666' fill='white' />
          </g>
          <g id='c_23'>
            <path id='t_45' d='M14.1699 12.0635L13.8403 11.049L13.3331 11.2138' fill='white' />
            <path id='t_46' d='M14.1699 12.0635L13.307 11.4365L13.6205 11.005' fill='white' />
          </g>
          <g id='c_24'>
            <path id='t_47' d='M12.916 12.0635L13.779 11.4365L13.4655 11.005' fill='white' />
            <path id='t_48' d='M12.916 12.0635L13.2456 11.049L13.7529 11.2138' fill='white' />
          </g>
          <g id='c_25'>
            <path id='t_49' d='M12.5283 10.8711L13.3913 11.4981L13.7048 11.0666' fill='white' />
            <path id='t_50' d='M12.5283 10.8711H13.595V11.4044' fill='white' />
          </g>
        </g>
        <g id='s_6'>
          <g id='c_26'>
            <path id='t_51' d='M11.2002 12.4756L10.8706 13.49L11.3778 13.6549' fill='white' />
            <path id='t_52' d='M11.2002 12.4756L11.5298 13.49L11.0226 13.6549' fill='white' />
          </g>
          <g id='c_27'>
            <path id='t_53' d='M12.2144 13.2129L11.1477 13.2129L11.1477 13.7462' fill='white' />
            <path id='t_54' d='M12.2144 13.2129L11.3514 13.8399L11.0379 13.4084' fill='white' />
          </g>
          <g id='c_28'>
            <path id='t_55' d='M11.8271 14.4053L11.4975 13.3908L10.9903 13.5556' fill='white' />
            <path id='t_56' d='M11.8271 14.4053L10.9642 13.7783L11.2777 13.3468' fill='white' />
          </g>
          <g id='c_29'>
            <path id='t_57' d='M10.5732 14.4053L11.4362 13.7783L11.1227 13.3468' fill='white' />
            <path id='t_58' d='M10.5732 14.4053L10.9029 13.3908L11.4101 13.5556' fill='white' />
          </g>
          <g id='c_30'>
            <path id='t_59' d='M10.1855 13.2129L11.0485 13.8399L11.362 13.4084' fill='white' />
            <path id='t_60' d='M10.1855 13.2129L11.2522 13.2129L11.2522 13.7462' fill='white' />
          </g>
        </g>
        <g id='s_7'>
          <g id='c_31'>
            <path id='t_61' d='M8 13.333L7.67038 14.3475L8.17761 14.5123' fill='white' />
            <path id='t_62' d='M8 13.333L8.32962 14.3475L7.82239 14.5123' fill='white' />
          </g>
          <g id='c_32'>
            <path id='t_63' d='M9.01416 14.0703L7.94749 14.0703L7.94749 14.6036' fill='white' />
            <path id='t_64' d='M9.01416 14.0703L8.15121 14.6973L7.83772 14.2658' fill='white' />
          </g>
          <g id='c_33'>
            <path id='t_65' d='M8.62695 15.2627L8.29734 14.2482L7.7901 14.413' fill='white' />
            <path id='t_66' d='M8.62695 15.2627L7.764 14.6357L8.07749 14.2042' fill='white' />
          </g>
          <g id='c_34'>
            <path id='t_67' d='M7.37305 15.2627L8.236 14.6357L7.92251 14.2042' fill='white' />
            <path id='t_68' d='M7.37305 15.2627L7.70267 14.2482L8.2099 14.413' fill='white' />
          </g>
          <g id='c_35'>
            <path id='t_69' d='M6.98535 14.0703L7.8483 14.6973L8.16179 14.2658' fill='white' />
            <path id='t_70' d='M6.98535 14.0703H8.05202V14.6036' fill='white' />
          </g>
        </g>
        <g id='s_8'>
          <g id='c_36'>
            <path id='t_71' d='M4.80029 12.4756L4.47067 13.49L4.9779 13.6549' fill='white' />
            <path id='t_72' d='M4.80029 12.4756L5.12991 13.49L4.62268 13.6549' fill='white' />
          </g>
          <g id='c_37'>
            <path id='t_73' d='M5.81445 13.2129L4.74779 13.2129L4.74779 13.7462' fill='white' />
            <path id='t_74' d='M5.81445 13.2129L4.9515 13.8399L4.63802 13.4084' fill='white' />
          </g>
          <g id='c_38'>
            <path id='t_75' d='M5.42725 14.4053L5.09763 13.3908L4.5904 13.5556' fill='white' />
            <path id='t_76' d='M5.42725 14.4053L4.56429 13.7783L4.87778 13.3468' fill='white' />
          </g>
          <g id='c_39'>
            <path id='t_77' d='M4.17334 14.4053L5.03629 13.7783L4.72281 13.3468' fill='white' />
            <path id='t_78' d='M4.17334 14.4053L4.50296 13.3908L5.01019 13.5556' fill='white' />
          </g>
          <g id='c_40'>
            <path id='t_79' d='M3.78564 13.2129L4.6486 13.8399L4.96208 13.4084' fill='white' />
            <path id='t_80' d='M3.78564 13.2129L4.85231 13.2129L4.85231 13.7462' fill='white' />
          </g>
        </g>
        <g id='s_9'>
          <g id='c_41'>
            <path id='t_81' d='M2.45752 10.1338L2.1279 11.1482L2.63513 11.3131' fill='white' />
            <path id='t_82' d='M2.45752 10.1338L2.78714 11.1482L2.27991 11.3131' fill='white' />
          </g>
          <g id='c_42'>
            <path id='t_83' d='M3.47168 10.8711L2.40501 10.8711L2.40501 11.4044' fill='white' />
            <path id='t_84' d='M3.47168 10.8711L2.60873 11.4981L2.29524 11.0666' fill='white' />
          </g>
          <g id='c_43'>
            <path id='t_85' d='M3.08447 12.0635L2.75485 11.049L2.24762 11.2138' fill='white' />
            <path id='t_86' d='M3.08447 12.0635L2.22152 11.4365L2.53501 11.005' fill='white' />
          </g>
          <g id='c_44'>
            <path id='t_87' d='M1.83057 12.0635L2.69352 11.4365L2.38003 11.005' fill='white' />
            <path id='t_88' d='M1.83057 12.0635L2.16018 11.049L2.66741 11.2138' fill='white' />
          </g>
          <g id='c_45'>
            <path id='t_89' d='M1.44287 10.8711L2.30582 11.4981L2.61931 11.0666' fill='white' />
            <path id='t_90' d='M1.44287 10.8711L2.50954 10.8711L2.50954 11.4044' fill='white' />
          </g>
        </g>
        <g id='s_10'>
          <g id='c_46'>
            <path id='t_91' d='M1.6001 6.93359L1.27048 7.94805L1.77771 8.11286' fill='white' />
            <path id='t_92' d='M1.6001 6.93359L1.92972 7.94805L1.42249 8.11286' fill='white' />
          </g>
          <g id='c_47'>
            <path id='t_93' d='M2.61426 7.6709L1.54759 7.6709L1.54759 8.20423' fill='white' />
            <path id='t_94' d='M2.61426 7.6709L1.75131 8.29787L1.43782 7.86639' fill='white' />
          </g>
          <g id='c_48'>
            <path id='t_95' d='M2.22705 8.86328L1.89743 7.84882L1.3902 8.01363' fill='white' />
            <path id='t_96' d='M2.22705 8.86328L1.3641 8.23631L1.67758 7.80483' fill='white' />
          </g>
          <g id='c_49'>
            <path id='t_97' d='M0.973145 8.86328L1.8361 8.23631L1.52261 7.80483' fill='white' />
            <path id='t_98' d='M0.973145 8.86328L1.30276 7.84882L1.80999 8.01363' fill='white' />
          </g>
          <g id='c_50'>
            <path id='t_99' d='M0.585449 7.6709L1.4484 8.29787L1.76189 7.86639' fill='white' />
            <path id='t_100' d='M0.585449 7.6709L1.65212 7.6709L1.65212 8.20423' fill='white' />
          </g>
        </g>
        <g id='s_11'>
          <g id='c_51'>
            <path id='t_101' d='M2.45752 3.7334L2.1279 4.74786L2.63513 4.91267' fill='white' />
            <path id='t_102' d='M2.45752 3.7334L2.78714 4.74786L2.27991 4.91267' fill='white' />
          </g>
          <g id='c_52'>
            <path id='t_103' d='M3.47168 4.4707H2.40501V5.00404' fill='white' />
            <path id='t_104' d='M3.47168 4.4707L2.60873 5.09767L2.29524 4.6662' fill='white' />
          </g>
          <g id='c_53'>
            <path id='t_105' d='M3.08447 5.66309L2.75485 4.64863L2.24762 4.81343' fill='white' />
            <path id='t_106' d='M3.08447 5.66309L2.22152 5.03611L2.53501 4.60464' fill='white' />
          </g>
          <g id='c_54'>
            <path id='t_107' d='M1.83057 5.66309L2.69352 5.03611L2.38003 4.60464' fill='white' />
            <path id='t_108' d='M1.83057 5.66309L2.16018 4.64863L2.66741 4.81343' fill='white' />
          </g>
          <g id='c_55'>
            <path id='t_109' d='M1.44287 4.4707L2.30582 5.09767L2.61931 4.6662' fill='white' />
            <path id='t_110' d='M1.44287 4.4707L2.50954 4.4707L2.50954 5.00404' fill='white' />
          </g>
        </g>
        <g id='s_12'>
          <g id='c_56'>
            <path id='t_111' d='M4.80029 1.39062L4.47067 2.40509L4.9779 2.56989' fill='white' />
            <path id='t_112' d='M4.80029 1.39062L5.12991 2.40509L4.62268 2.56989' fill='white' />
          </g>
          <g id='c_57'>
            <path id='t_113' d='M5.81445 2.12793L4.74779 2.12793L4.74779 2.66126' fill='white' />
            <path id='t_114' d='M5.81445 2.12793L4.9515 2.7549L4.63802 2.32342' fill='white' />
          </g>
          <g id='c_58'>
            <path id='t_115' d='M5.42725 3.32031L5.09763 2.30585L4.5904 2.47066' fill='white' />
            <path id='t_116' d='M5.42725 3.32031L4.56429 2.69334L4.87778 2.26187' fill='white' />
          </g>
          <g id='c_59'>
            <path id='t_117' d='M4.17334 3.32031L5.03629 2.69334L4.72281 2.26187' fill='white' />
            <path id='t_118' d='M4.17334 3.32031L4.50296 2.30585L5.01019 2.47066' fill='white' />
          </g>
          <g id='c_60'>
            <path id='t_119' d='M3.78564 2.12793L4.6486 2.7549L4.96208 2.32342' fill='white' />
            <path id='t_120' d='M3.78564 2.12793H4.85231V2.66126' fill='white' />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_406_30268'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export { StarsIcon }
