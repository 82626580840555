import { TotalRes, TotalResRaw } from './types'
import * as fetch from 'app/api/fetch'

export interface DashboardGraphParItem {
  fuel: string
  startDate: number
  idVehicle: string
  endDate: number
  month: number
  year: number
  co2: string
  co2Diesel: string
  distance: string
}

export interface DashboardGraphRes extends TotalRes {
  req: string
  par: DashboardGraphParItem[]
  user: string
}

export const getDashboardGraph = async (): Promise<DashboardGraphRes> => {
  try {
    const response = await fetch.post<{ req: string; par: object }, TotalResRaw>({ req: 'dashboardGraph', par: {} })

    return {
      ...response,
      par: JSON.parse(response.par),
    }
  } catch (error: any) {
    throw new Error(error)
  }
}
