import { PaletteOptions } from '@mui/material'

export const colors: PaletteOptions = {
  primaryMain: '#006EB8',
  primaryContrast: '#FFFFFF',
  textPrimary: '#03032E',
  textSecondary: '#565673',
  blue900: '#002F4F',
  backgroundPaper: '#FFF',
  colorsGreyblueGrey50: '#ECECF0',
  grey400: '#E4E3E5',
  grey50: '#f3f6f8',
  green700: '#358346',
  green800: '#296536',
  blue300: '#54A0D2',
  orange500: '#FF9800',
  red500: '#F44336',
}
