const ArrowDropdownIcon = ({ color = '#565673' }: { color?: string }) => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='expand_more_FILL0_wght300_GRAD0_opsz24 (1) 1'>
        <path
          id='Vector'
          d='M11.9995 15.5387L6.3457 9.88488L7.39953 8.83105L11.9995 13.4311L16.5995 8.83105L17.6534 9.88488L11.9995 15.5387Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export { ArrowDropdownIcon }
