import { createSlice } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { ParVehiclesRes } from '../../../../features/vehiclesList/api/services/vehiclesListService'
import { getVehiclesList } from 'shared/features'
import { SortOrder, sortArrayByField } from '../utils/sortArr'

interface VehicleListSliceState {
  vehicleList: ParVehiclesRes[]
  sortedValue: VehicleListSortItem
  isLoading: boolean
  error?: ReduxError
}

export interface VehicleListSortItem {
  field: keyof ParVehiclesRes
  direction: SortOrder
}

const initialState: VehicleListSliceState = {
  vehicleList: [],
  sortedValue: { field: 'id', direction: 'asc' },
  isLoading: false,
  error: { message: '' },
}

export const vehicleListSlice = createSlice({
  name: 'vehicleList',
  initialState,
  reducers: {
    selectVehicle: (state, { payload }: { payload: string }) => {
      state.vehicleList = state.vehicleList.map((el) => {
        if (el.id == payload) {
          el.isSelected = !el.isSelected
        }
        return el
      })
    },
    selectAllVehicles: (state) => {
      state.vehicleList = state.vehicleList.map((el) => {
        el.isSelected = true
        return el
      })
    },
    resetSelectAllVehicles: (state) => {
      state.vehicleList = state.vehicleList.map((el) => {
        el.isSelected = false
        return el
      })
    },
    sortVehicleList: (state, { payload }: { payload: VehicleListSortItem }) => {
      state.vehicleList = sortArrayByField(state.vehicleList, payload.field, payload.direction)
      state.sortedValue = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehiclesList.pending, (state) => {
      state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(getVehiclesList.fulfilled, (state, { payload }) => {
      const vehicleListNotSelected = payload.par.map((el) => {
        el.isSelected = false
        return el
      })
      state.vehicleList = sortArrayByField<ParVehiclesRes>(vehicleListNotSelected, 'id')
      state.sortedValue = { field: 'id', direction: 'asc' }
      state.isLoading = false
    })
    builder.addCase(getVehiclesList.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
  },
})

export const vehicleListReducer = vehicleListSlice.reducer
