import { apiUrl } from 'app/utils/apiUrl'

async function http<T>(config: RequestInit): Promise<T> {
  const request = new Request(apiUrl, config)
  const response = await fetch(request)
  if (!response.ok) {
    if (response.statusText) {
      throw new Error(response.statusText)
    } else {
      const responseJsonError = await response.json()
      throw new Error(responseJsonError)
    }
  }
  const responseObj = await response.json()
  return responseObj
}

export async function post<T, U>(body: T, config?: RequestInit): Promise<U> {
  const init: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    credentials: 'include',
    ...config,
  }

  return await http<U>(init)
}
