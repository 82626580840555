import { createSlice } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { logoutUser } from '../action/logoutActions'
import { userIdSessionStorageKey, userNameSessionStorageKey } from 'shared/utils'
import { routes } from 'app'

interface LogoutSliceState {
  isLoading: boolean
  error?: ReduxError
}

const initialState: LogoutSliceState = {
  isLoading: false,
  error: { message: '' },
}

const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logoutUser.pending, (state) => {
      // state.isLoading = true
      state.error = {
        message: '',
      }
    })
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.isLoading = false
      window.location.pathname = routes.dashboard
      setTimeout(() => {
        sessionStorage.setItem(userIdSessionStorageKey, '')
        sessionStorage.setItem(userNameSessionStorageKey, '')
      }, 0)
    })
    builder.addCase(logoutUser.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = {
        message: payload?.message,
      }
    })
  },
})

export const logoutReducer = logoutSlice.reducer
