import { createSlice } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { DashboardFuelType } from 'features/dashboard/api/services/types'

interface DashboardFuelSliceState {
  dashboardFuel: DashboardFuelType
  isLoading: boolean
  error?: ReduxError
}

const initialState: DashboardFuelSliceState = {
  dashboardFuel: 'Gasoline',
  isLoading: false,
  error: { message: '' },
}

export const dashboardFuelSlice = createSlice({
  name: 'dashboardFuelSlice',
  initialState,
  reducers: {
    selectFuel: (state, { payload }: { payload: DashboardFuelType }) => {
      state.dashboardFuel = payload
    },
  },
})

export default dashboardFuelSlice.reducer
