import { store } from 'app/store'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import { theme } from './utils/theme/theme'
import { AppContainerInner } from './components/AppContainerInner'

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppContainerInner />
      </ThemeProvider>
    </Provider>
  )
}
