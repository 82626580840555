import { Typography } from '@mui/material'

interface TitleBodyProps {
  title: string
  desc: string
}

const TitleBody = ({ title, desc }: TitleBodyProps) => {
  return (
    <>
      <Typography variant='h4'>{title}</Typography>
      <Typography variant='body2'>{desc}</Typography>
    </>
  )
}

export { TitleBody }
