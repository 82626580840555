import { createTheme } from '@mui/material/styles'
import {
  customOutlinedMuiButton,
  customOutlinedPrimaryMuiButton,
  customPalette,
  customStylesMuiButton,
  customStylesMuiPaper,
  customTypography,
} from './utils'
import { customStylesMuiButtongroup } from './utils/muiButtonGroupOvverides'

export const theme = createTheme({
  palette: customPalette,
  spacing: 4,
  typography: customTypography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: customStylesMuiButton,
      },
      variants: [customOutlinedMuiButton, customOutlinedPrimaryMuiButton],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: customStylesMuiButtongroup,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: customStylesMuiPaper,
      },
    },
  },
})
