import { ButtonProps, CSSInterpolation, Interpolation, Theme } from '@mui/material'
import { colors } from './colors'

export const customStylesMuiButton: CSSInterpolation = {
  fontSize: '16px',
  textTransform: 'capitalize',
  borderRadius: '8px',
  boxShadow:
    '0px 5px 5px -3px rgba(0, 0, 0, 0.10), 0px 8px 10px 1px rgba(0, 0, 0, 0.10), 0px 3px 14px 2px rgba(0, 0, 0, 0.10)',
}

export const customOutlinedMuiButton: { props: Partial<ButtonProps>; style: Interpolation<{ theme: Theme }> } = {
  props: { variant: 'outlined', color: 'secondary' },
  style: {
    border: '1px solid #03032E',
    boxShadow: 'none',
    color: '#03032E',
    fontSize: '14px',
    fontWeight: 500,
  },
}
export const customOutlinedPrimaryMuiButton: { props: Partial<ButtonProps>; style: Interpolation<{ theme: Theme }> } = {
  props: { variant: 'outlined', color: 'primary' },
  style: {
    border: `1px solid ${colors.primaryMain}`,
    boxShadow: 'none',
    color: colors.primaryMain,
    fontSize: '14px',
    fontWeight: 500,
  },
}
