import * as fetch from 'app/api/fetch'

export interface LogoutReq {
  req: string
  par: ParLogoutReq
}

export interface LogoutRes {
  req: string
  login?: string
  par?: {
    logoff: string
  }
}

export interface ParLogoutReq {
  logging: string
}

export const logout = async (): Promise<LogoutRes> => {
  try {
    const response = await fetch.post<LogoutReq, LogoutRes>({ req: 'logoff', par: { logging: 'off' } })
    return response
  } catch (error: any) {
    throw new Error(error)
  }
}
