import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import {
  TripRes,
  ParTripReq,
  getVehicleTrip,
  ParVehicleHistoryReq,
  VehicleHistoryRes,
  getVehicleHistory,
  getVehicleHistoryTrips,
  VehicleHistoryTripsRes,
} from '../../api/services/vehiclesHistoryService'

export const getVehicleHistoryAction = createAsyncThunk<
  VehicleHistoryRes,
  ParVehicleHistoryReq,
  { rejectValue: ReduxError }
>('vehicleHistory', async ({ idvehicle, ntrips }, thunkApi) => {
  const response = await getVehicleHistory(idvehicle, ntrips)
    .then((res) => {
      if (res.req !== 'data') {
        return thunkApi.rejectWithValue({
          message: 'error',
        })
      } else {
        return res
      }
    })
    .catch((err) => {
      const { message } = err
      return thunkApi.rejectWithValue({
        message: message,
      })
    })
  return response
})

export const getAllVehicleHistoryTrips = createAsyncThunk<
  VehicleHistoryTripsRes,
  ParVehicleHistoryReq,
  { rejectValue: ReduxError }
>('vehicleHistoryTrips', async ({ idvehicle, ntrips, week }, thunkApi) => {
  const response = await getVehicleHistoryTrips(idvehicle, ntrips, week)
    .then((res) => {
      if (res.req !== 'trips') {
        return thunkApi.rejectWithValue({
          message: 'error',
        })
      } else {
        return res
      }
    })
    .catch((err) => {
      const { message } = err
      return thunkApi.rejectWithValue({
        message: message,
      })
    })
  return response
})

export const getTripDetails = createAsyncThunk<TripRes, ParTripReq, { rejectValue: ReduxError }>(
  'vehicleTrip',
  async ({ idtrip, ntrips }, thunkApi) => {
    const response = await getVehicleTrip(idtrip, ntrips)
      .then((res) => {
        if (res.req !== 'logs') {
          return thunkApi.rejectWithValue({
            message: 'error',
          })
        } else {
          return res
        }
      })
      .catch((err) => {
        const { message } = err
        return thunkApi.rejectWithValue({
          message: message,
        })
      })
    return response
  }
)
