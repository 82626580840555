import { useAppDispatch, useAppSelector } from 'app/hooks'
import React, { useEffect } from 'react'
import { FullPageSpinner } from 'shared/components'
import { readUserFromSessionStorage, selectIsAuthenticated, selectUserId } from 'shared/features'

const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ './AppAuthenticated'))
const UnauthenticatedApp = React.lazy(() => import('./AppUnAuthenticated'))

const AppContainerInner = () => {
  const dispatch = useAppDispatch()
  const isAuthenticated = useAppSelector(selectIsAuthenticated)
  const userId = useAppSelector(selectUserId)

  useEffect(() => {
    dispatch(readUserFromSessionStorage())
  }, [])
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {userId || isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  )
}

export { AppContainerInner }
