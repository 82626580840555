import { Box, useTheme } from '@mui/system'
import { useState, useEffect } from 'react'
import { DotBigIcon } from 'shared/assets'
import { DateHour } from './DateHour'
import dayjs from 'dayjs'

const DateFormatedCell = ({ date }: { date: number }) => {
  const [color, setColor] = useState('')
  const {
    palette: { green700, green800, blue300, orange500, red500 },
  } = useTheme()

  useEffect(() => {
    const currentDate = new Date()
    const inputDate = new Date(dayjs.unix(date).format())

    // Calculate the difference in days
    const timeDiff = Math.abs(currentDate.getTime() - inputDate.getTime())
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

    if (diffDays === 0) {
      // Today
      setColor(green700)
    } else if (diffDays === 1) {
      // Yesterday
      setColor(green800)
    } else if (diffDays <= 7) {
      // Within 7 days
      setColor(blue300)
    } else if (diffDays > 7 && diffDays <= 30) {
      // Over 7 days but within a month
      setColor(orange500)
    } else {
      // Over a month
      setColor(red500)
    }
  }, [date])
  return (
    <Box sx={{ color: color, display: 'flex' }}>
      {date ? (
        <Box display='flex'>
          <Box sx={{ mr: 2 }}>
            <DotBigIcon color={color} />
          </Box>
          <DateHour date={date} />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export { DateFormatedCell }
