import { LoginRes, ParLoginReq, login } from 'features/loginPage/api/services/loginService'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { setAuthenticated, setUser } from 'shared/features'
import { logout } from 'shared/features/logout/services/logoutService'

export const loginUser = createAsyncThunk<
  { res: LoginRes; userName: string },
  ParLoginReq,
  { rejectValue: ReduxError }
>('loginUser', async (cred: ParLoginReq, thunkApi) => {
  await logout()
  const response = await login(cred)
    .then((res) => {
      if (res.login !== 'ok') {
        return thunkApi.rejectWithValue({
          message: res.login,
        })
      } else if (res.error) {
        return thunkApi.rejectWithValue({
          message: res.error.msg,
        })
      } else {
        thunkApi.dispatch(setUser({ uid: res.uid, uname: cred.uname }))
        thunkApi.dispatch(setAuthenticated(true))
        return {
          res: res,
          userName: cred.uname,
        }
      }
    })
    .catch((err) => {
      const { message } = err
      return thunkApi.rejectWithValue({
        message: message,
      })
    })
  return response
})
