const HomeIcon = ({ color = '#565673' }: { color?: string }) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='home_FILL0_wght300_GRAD0_opsz24 (2) 1'>
        <path
          id='Vector'
          d='M4.99998 15.8332H7.78846V10.8813H12.2115V15.8332H15V8.33317L9.99998 4.56715L4.99998 8.33317V15.8332ZM4.99998 17.0832C4.65917 17.0832 4.36565 16.96 4.1194 16.7138C3.87313 16.4675 3.75 16.174 3.75 15.8332V8.33317C3.75 8.1359 3.79413 7.949 3.8824 7.77249C3.97065 7.59597 4.09265 7.45061 4.2484 7.3364L9.2484 3.57038C9.36377 3.48598 9.484 3.42401 9.60908 3.38449C9.73417 3.34496 9.86447 3.3252 9.99998 3.3252C10.1355 3.3252 10.2658 3.34496 10.3909 3.38449C10.516 3.42401 10.6362 3.48598 10.7516 3.57038L15.7516 7.3364C15.9073 7.45061 16.0293 7.59597 16.1176 7.77249C16.2058 7.949 16.25 8.1359 16.25 8.33317V15.8332C16.25 16.174 16.1268 16.4675 15.8806 16.7138C15.6343 16.96 15.3408 17.0832 15 17.0832H10.9615V12.1312H9.03844V17.0832H4.99998Z'
          fill={color}
        />
      </g>
    </svg>
  )
}

export { HomeIcon }
