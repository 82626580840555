import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { VehiclesRes, getAllVehicles } from '../../../../features/vehiclesList/api/services/vehiclesListService'
import { logoutUser } from 'shared/features/logout/action/logoutActions'
import { vehicleListSlice } from 'shared/features'

export const getVehiclesList = createAsyncThunk<VehiclesRes, any, { rejectValue: ReduxError }>(
  'vehiclesList',
  async (_, thunkApi) => {
    const response = await getAllVehicles()
      .then((res) => {
        if (res.req !== 'data') {
          return thunkApi.rejectWithValue({
            message: 'error',
          })
        } else if (res.login == 'login') {
          thunkApi.dispatch(logoutUser(null))
          return res
        } else {
          return res
        }
      })
      .catch((err) => {
        const { message } = err
        return thunkApi.rejectWithValue({
          message: message,
        })
      })
    return response
  }
)

export const { selectVehicle, resetSelectAllVehicles, selectAllVehicles, sortVehicleList } = vehicleListSlice.actions
