import { createAsyncThunk } from '@reduxjs/toolkit'
import { ReduxError } from 'app/api/types'
import { LogoutRes, logout } from '../services/logoutService'
import { setAuthenticated, setUser } from 'shared/features'

export const logoutUser = createAsyncThunk<LogoutRes, any, { rejectValue: ReduxError }>(
  'logoutUser',
  async (_, thunkApi) => {
    const response = await logout()
      .then((res) => {
        if (res.login !== 'login' || res.par?.logoff !== 'ok') {
          thunkApi.dispatch(
            setUser({
              uid: '',
              uname: '',
            })
          )
          return res
        } else {
          return thunkApi.rejectWithValue({
            message: res.login,
          })
        }
      })
      .catch((err) => {
        const { message } = err
        return thunkApi.rejectWithValue({
          message: message,
        })
      })
    return response
  }
)
