import '@fontsource/lato/700.css'
import '@fontsource/lato/400.css'
import { colors } from './colors'

const { textPrimary, textSecondary } = colors

export const customTypography = {
  h1: {
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '150%',
    color: textPrimary,
  },
  h2: {
    fontSize: '24px',
    fontFamily: 'Lato, Arial, sans-serif',
    fontWeight: '700',
    lineHeight: '130%',
    color: textPrimary,
  },
  h3: {
    fontSize: '18px',
    fontFamily: 'Lato, Arial, sans-serif',
    fontWeight: '700',
    lineHeight: '130%',
    color: textPrimary,
  },
  h4: {
    fontSize: '16px',
    fontFamily: 'Lato, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '157%',
    color: textPrimary,
  },
  h6: {
    fontSize: '20px',
    fontFamily: 'Lato, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '32px',
    color: textPrimary,
  },
  body1: {
    fontSize: '16px',
    fontFamily: 'Lato, Arial, sans-serif',
    lineHeight: '150%',
    color: textSecondary,
  },
  body2: {
    fontSize: '14px',
    fontFamily: 'Lato, Arial, sans-serif',
    lineHeight: '150%',
    color: textSecondary,
  },
  caption: {
    fontSize: '12px',
    fontFamily: 'Lato, Arial, sans-serif',
    lineHeight: '150%',
    color: textSecondary,
  },
}
