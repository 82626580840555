import { AppBar, Box, Tab, Tabs, useTheme } from '@mui/material'
import { useState, SyntheticEvent } from 'react'
import { HomeIcon } from 'shared/assets/icons/HomeIcon'
import { CarIcon } from 'shared/assets/icons/CarIcon'
import { MapIcon } from 'shared/assets/icons/MapIcon'
import { ReportIcon } from 'shared/assets/icons/ReportIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from 'app'
import { useAppDispatch } from '../../../app/hooks'
import { clearSelectedTrip, clearSelectedVehicle } from 'features/vehiclesList/logic/slices/vehicleHistorySlice'

interface LinkTabProps {
  label?: string
  href: string
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined
}

const LinkTab = (props: LinkTabProps) => {
  const navigate = useNavigate()

  return (
    <Tab
      component='a'
      sx={{
        textTransform: 'capitalize',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
        navigate(props.href)
      }}
      {...props}
      iconPosition='start'
    />
  )
}

const Navbar = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const tabs = [
    {
      icon: (color?: string) => <HomeIcon color={color} />,
      label: 'Dashboard',
      href: routes.dashboard,
    },
    {
      icon: (color?: string) => <CarIcon color={color} />,
      label: 'Vehicles',
      href: routes.vehicleList,
    },
    {
      icon: (color?: string) => <MapIcon color={color} />,
      label: 'Map',
      href: routes.map,
    },
    {
      icon: (color?: string) => <ReportIcon color={color} />,
      label: 'Report',
      href: routes.report,
    },
  ]
  const selecteTabIdx = tabs.findIndex((el) => el.href == pathname)
  const [value, setValue] = useState(selecteTabIdx > -1 ? selecteTabIdx : 0)
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
    dispatch(clearSelectedTrip())
    dispatch(clearSelectedVehicle())
  }

  const {
    palette: { backgroundPaper, colorsGreyblueGrey50, primaryMain },
  } = useTheme()
  return (
    <AppBar
      position='static'
      sx={{
        p: 0,
        backgroundColor: backgroundPaper,
        borderTop: `1px solid ${colorsGreyblueGrey50}`,
        borderBottom: `1px solid ${colorsGreyblueGrey50}`,
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.08)',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Tabs centered value={value} onChange={handleChange} aria-label='icon label tabs example'>
        {tabs.map(({ icon, label, href }, index) => (
          <LinkTab
            key={href}
            icon={<Box sx={{ mr: 1 }}>{icon(index === value ? primaryMain : undefined)}</Box>}
            label={label}
            href={href}
          />
        ))}
      </Tabs>
    </AppBar>
  )
}

export { Navbar }
